import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyaplhanumeric]',
})
export class OnlyaplhanumericDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input') onKeyDown() {
    const reg = /^[A-Za-z0-9,.]*$/;
    const value = this.el.nativeElement.value;
    if (!reg.test(value)) {
      this.el.nativeElement.value = value.slice(0, -1);
    }
  }
}
